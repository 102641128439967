import {
  Box,
  IconButton,
  List,
  ListItemText,
  Typography,
  Popover,
  MenuItem,
  CircularProgress,
  InputBase,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import React from "react";
import { useGetAccountCompanies } from "../../graphql/queries/GetAccountCompanies";
import { useGetAccountProjects } from "../../graphql/queries/GetAccountProjects";
import {
  Colors,
  DialogDivider,
  ExtractsCheckbox,
  HeaderViewController,
  HeaderViewControllerTab,
  MyDialogActions,
  OutlineWithLabelTextField,
} from "../../styles";
import Loading from "../shared/Loading";
import TeamHomeEmptyState from "./TeamHomeEmptyState";
import CloseIcon from "@mui/icons-material/Close";
import {
  FeedbackDialog,
  ManageCompaniesDialogContent,
  ManageCompaniesListIcon,
  ManageCompaniesListItem,
  SearchCloseIcon,
  SearchCloseIconButton,
} from "./styles";
import { Account, Project } from "../../types";
import PersonalProjectsList from "./PersonalProjectsList";
import { useLoadProjectsOnCompanies } from "../../graphql/mutations/LoadProjectsOnCompanies";
import CollaboratorProjectList from "./CollaboratorProjectList";
import { AccountProjectContext } from "../../contexts/AccountProjectContextProvider";
import useNavigationHook from "../../hooks/UseNavigationHook";
import { isAnnualLicense, isEmpty, isOwnerOrAdmin, isPilotOrCustomer } from "../../utils/utils";
import { useUpdateAccountName } from "../../graphql/mutations/UpdateAccountName";
import SearchIcon from "@mui/icons-material/Search";
import AddProjectsAnnualLicenseModal from "./AddProjectsAnnualLicenseModal";
import AddProjectsProjectLicenseModal from "./AddProjectsProjectLicenseModal";
import AddProjectFreeTrialLicenseModal from "./AddProjectFreeTrialLicenseModal";
import { Button } from "../shared/Button";
import ExtractsProIntegrationPermissionModal from "../ExtractsProIntegrationPermissionModal";
import EnableDmsaOnProjectsModal from "./EnableDmsaOnProjectsModal";
import { Add } from "@mui/icons-material";
import { BodyCard } from "../shared/BodyCard";
import { BodyCardHeaderBar } from "../shared/BodyCardHeaderBar";
import AddCircle from "@mui/icons-material/AddCircle";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { NewAutomationContext } from "../../contexts/EditAutomationContextProvider";
import { newAutomationInitialState } from "../../contexts/EditAutomationContext";
import { AutomationList } from "./AutomationsList";
import { useGetExtractAutomations } from "../../graphql/queries/automation/GetExtractAutomations";
import AccountUsersDataGrid from "./AccountUsersDataGrid";

const licensedProjects = (projects: Project[]): Project[] => {
  return projects.filter((p) => {
    if (p.licensed) {
      return p.licenseType !== "trial";
    } else {
      return false;
    }
  });
};

const TeamHome = (props: {
  account: Account;
  handleAccountNamed: (accountId: string, newName: string) => void;
  handleLicenseUsed: (accountId: string, numberOfLicenses: number) => void;
  handleConfiguredAutomations: (accountId: string) => void;
  handleFreeTrialUsed: (accountId: string) => void;
}): JSX.Element => {
  let timeout;
  const { account, handleAccountNamed, handleLicenseUsed, handleFreeTrialUsed, handleConfiguredAutomations } = props;
  const { state, dispatch } = React.useContext(AccountProjectContext);
  const navigateTo = useNavigationHook();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isSetupAccountModalOpen, setSetupAccountModalOpen] =
    React.useState(false);
  const [isAddProjectsModalOpen, setIsAddProjectsModalOpen] =
    React.useState(false);
  const [isPermissionRequiredModalOpen, setIsPermissionRequiredModalOpen] =
    React.useState({
      open: false,
      procoreProjectServerIds: []
    });
  const [enableDmsaOnProjectsModalState, setEnableDmsaOnProjectsModalState] =
    React.useState({
      open: false,
      procoreProjects: []
    });
  const [accountName, setAccountName] = React.useState("");
  const [moreAccountOptionsAnchorEl, setMoreAccountOptionsAnchorEl] =
    React.useState(null);
  const [isAppInstallModalOpen, setIsAppInstallModalOpen] =
    React.useState(false);
  const [companiesToEnable, setCompaniesToEnable] = React.useState<
    Record<number, boolean>
  >({});
  const { loading, data, refetch } = useGetAccountProjects(account.id, true);
  const [
    fetchAccountCompanies,
    { loading: loadingAccountCompanies, data: accountCompanyData },
  ] = useGetAccountCompanies(account.id);
  const [searchProjectsValue, setSearchProjectsValue] = React.useState("");
  const [
    updateAccountName,
    { loading: loadingUpdateAccountName, data: updateAccountNameData },
  ] = useUpdateAccountName({ accountId: account.id, name: accountName });
  const [
    loadProjectsOnCompanies,
    { loading: loadingProjectsOnCompanies, data: loadedCompanies },
  ] = useLoadProjectsOnCompanies({
    accountId: account.id,
    procoreCompanyServerIds: Object.entries(companiesToEnable)
      .filter(([_key, value]) => value)
      .map(([key, _value]) => parseInt(key)),
  });

  const { loading: loadingExtractAutomations, data: extractAutomationsData, refetch: refetchAutomations } = useGetExtractAutomations(
    {
      accountId: account.id,
    },
    false,
    'no-cache'
  );

  const {dispatch: dispatchAutomation} = React.useContext(NewAutomationContext)

  React.useEffect(() => {
    if (loadingAccountCompanies || !accountCompanyData) {
      return;
    }
    const records: Record<number, boolean> =
      accountCompanyData.procoreCompanies.reduce((acc, current) => {
        acc[current.procoreServerId] = current.enabled;
        return acc;
      }, {});
    setCompaniesToEnable(records);
  }, [loadingAccountCompanies]);
  React.useEffect(() => {
    if (loadingUpdateAccountName || !updateAccountNameData) {
      return;
    }
    if (updateAccountNameData.updateAccountName.success) {
      handleAccountNamed(account.id, accountName);
      setSetupAccountModalOpen(false);
      openCompaniesModal();
    }
  }, [loadingUpdateAccountName]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  React.useEffect(() => {
    if (loadingProjectsOnCompanies || !loadedCompanies) {
      return;
    }
    const data = loadedCompanies.loadProjectsOnCompanies;
    if ((data.successfulProcoreCompanyServerIds || []).length > 0) {
      refetch();
    }
    if ((data.failedProcoreCompanyServerIds || []).length > 0) {
      setIsAppInstallModalOpen(true);
    }
  }, [loadingProjectsOnCompanies]);

  React.useEffect(() => {
    if (loading || !data) {
      return;
    }
    if (state.procoreProjectServerIdToSelect) {
      const projects = data.procoreProjects.filter((p) => {
        return (
          p.licensed === true &&
          p.procoreServerId == state.procoreProjectServerIdToSelect
        );
      });
      const project = projects[0];
      if (project) {
        dispatch({
          type: "SET_ACCOUNT_PROJECT",
          value: {
            accountProject: {
              accountId: account.id,
              internal: account.internal,
              closeoutLogEnabled: account.closeoutLogEnabled && project.dmsaEnabled,
              configuredAutomations: account.configuredAutomations,
              closeoutLogFileRequestEnabled: account.closeoutLogFileRequestEnabled && project.dmsaEnabled,
              automaticExportsEnabled: account.automaticExportsEnabled && project.dmsaEnabled,
              procoreCompanyServerId: project.procoreCompanyServerId,
              procoreProjectServerId: project.procoreServerId,
              projectName: project.displayName || project.name,
              dateFormat: account.dateFormat,
              timeZone: project.timeZone,
              licensed: project.licensed,
              licenseType: project.licenseType,
              licenseEndDate: project.licenseEndDate,
              appBaseUrl: account.appBaseUrl,
            },
          },
        });
        navigateTo(
          `/teams/${account.id}/projects/${project.procoreServerId}/packages`
        );
      }
    }
  }, [loading]);
  const openCompaniesModal = () => {
    setIsModalOpen(true);
    fetchAccountCompanies();
  };
  const handleProjectUsersTabChanged = (event, newValue) => {
    if (newValue) {
      dispatch({
        type: 'SET_SELECTED_TAB',
        value: newValue,
      });
    }
  };
  const onAllowAccessClick = () => {
    setEnableDmsaOnProjectsModalState({
      open: true,
      procoreProjects: data.procoreProjects.filter(p => !p.dmsaEnabled),
    })
  }
  const updateCompaniesEnabled = (
    procoreServerId: number,
    checked: boolean
  ): void => {
    const records: Record<number, boolean> = (
      Object.keys(companiesToEnable) as unknown as Array<number>
    ).reduce((acc, current) => {
      if (current === procoreServerId) {
        acc[procoreServerId] = checked;
      } else {
        acc[current] = companiesToEnable[current];
      }
      return acc;
    }, {});
    setCompaniesToEnable(records);
  };
  const loadingTeamHome = loading || loadingProjectsOnCompanies;
  const displayUsersTab =
    isOwnerOrAdmin(account) &&
    account.setup &&
    ((isAnnualLicense(account) && isPilotOrCustomer(account)) ||
      (data && licensedProjects(data.procoreProjects).length > 0));

  return (
    <>
      <BodyCard sx={{borderRadius: '8px 8px 0px 0px'}}>
        <TabContext value={state.selectedTab}>
          <BodyCardHeaderBar title={account.name}>
            <Box sx={{
              display: 'flex',
              flexGrow: '1',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginLeft: '8px',
              gap: '8px',
            }}>
              <HeaderViewController
                orientation="horizontal"
                value={state.selectedTab}
                onChange={handleProjectUsersTabChanged}
                aria-label="Vertical tabs example"
                disabled={true}
              >
                <HeaderViewControllerTab
                  wrapped={true}
                  label="Projects"
                  value={"projects"}
                  disabled={loadingTeamHome}
                />
                {displayUsersTab && (
                  <HeaderViewControllerTab
                    wrapped={true}
                    label="Users"
                    value={"users"}
                    disabled={loadingTeamHome}
                  />
                )}
                {isOwnerOrAdmin(account) && account.automaticExportsEnabled && (
                  <HeaderViewControllerTab
                    wrapped={true}
                    label="Company Automations"
                    value={"automations"}
                    disabled={loadingTeamHome}
                  />
                )}
              </HeaderViewController>
              {account.setup &&
                data && (
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        gap: '8px',
                      }}
                    >
                      { state.selectedTab === "projects" && (
                        <Box
                          display={"flex"}
                          sx={{ gap: "8px" }}
                          alignItems={"center"}
                        >
                        <Box
                          sx={{
                            minHeight: '40px',
                            maxHeight: '40px',
                            flex: '1 0',
                            borderRadius: '20px',
                            paddingRight: '4px',
                            border: `1px solid ${Colors.mediumGray}`,
                            '&:hover': {
                              border: `1px solid ${Colors.mediumDarkGray}`,
                            },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingLeft: '12px'
                          }}>
                          <SearchIcon sx={{fontSize: '18px'}}/>
                          <InputBase
                            sx={{
                              ml: 1,
                              flex: 1,
                              fontSize: '0.875rem',
                            }}
                            fullWidth={true}
                            placeholder="Search..."
                            value={searchProjectsValue}
                            endAdornment={
                              (searchProjectsValue || "").trim() ? (
                                <SearchCloseIconButton
                                  size={"small"}
                                  onClick={() => {
                                    setSearchProjectsValue("");
                                  }}
                                  style={{
                                    visibility: (searchProjectsValue || "").trim()
                                      ? "visible"
                                      : "hidden",
                                  }}
                                >
                                  <SearchCloseIcon />
                                </SearchCloseIconButton>
                              ) : null
                            }
                            onChange={(evt) => {
                              setSearchProjectsValue(evt.target.value);
                            }}
                            inputProps={{ "aria-label": "search" }}
                          />
                        </Box>
                          {
                            (isOwnerOrAdmin(account) && (account.licenseType === "project" || isPilotOrCustomer(account))) && (
                              <Button
                                buttonborderstyle='pill'
                                variant="outlined"
                                startIcon={<AddCircle/>}
                                disabled={account.licenseType === "annual" && account.numberOfLicenses <= 0}
                                onClick={() => {
                                  setIsAddProjectsModalOpen(true);
                                }}
                                disableElevation={true}
                              >
                                Add Projects
                              </Button>
                            )
                          }
                          {
                            account.licenseType === 'annual' && !isPilotOrCustomer(account) && account.freeTrialAvailable && (
                              <Button
                                buttonborderstyle='pill'
                                variant="outlined"
                                onClick={() => {
                                  setIsAddProjectsModalOpen(true);
                                }}
                                disableElevation={true}
                              >
                                Start Free Trial
                              </Button>
                            )
                          }
                        </Box>
                      )}
                      {state.selectedTab === "automations" && (
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          style={{ gap: "6px" }}
                          alignItems={"center"}
                        >
                          <Button
                            variant="outlined"
                            startIcon={<Add />}
                            buttonborderstyle="pill"
                            onClick={ ()=>{
                              dispatchAutomation({type: 'SET_AUTOMATION', value: {
                                ...newAutomationInitialState,
                                accountId: account.id,
                              }})
                              navigateTo(`/teams/${account.id}/automations/new`);
                            }}
                          >
                            New Automation
                          </Button>
                        </Box>
                      )}
                      <Button
                        variant="outlined"
                        buttonborderstyle="pill"
                        startIcon={<MoreHoriz fontSize="small" />}
                        sx={{
                          minWidth: '40px',
                          paddingX: '9px',
                          '& .MuiButton-startIcon': {
                            margin: '0px',
                          },
                        }}
                        onClick={(event) =>
                          setMoreAccountOptionsAnchorEl(event.currentTarget)
                        }
                      />
                      <Popover
                        open={Boolean(moreAccountOptionsAnchorEl)}
                        anchorEl={moreAccountOptionsAnchorEl}
                        onClose={() => setMoreAccountOptionsAnchorEl(null)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          key="load-projects"
                          onClick={() => {
                            setMoreAccountOptionsAnchorEl(null);
                            openCompaniesModal();
                          }}
                        >
                          {"Reselect Companies"}
                        </MenuItem>
                      </Popover>
                    </Box>
                )}
              </Box>
            </BodyCardHeaderBar>
            <TabPanel
              value={"projects"}
              sx={{
                padding: '0px 28px',
                display: state.selectedTab === "projects" ? "flex" : "none",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {loadingTeamHome ? (
                <Loading loadingLabel="Loading projects..." />
              ) : isOwnerOrAdmin(account) && !account.setup ? (
                <TeamHomeEmptyState
                  onClickGettingStarted={() => {
                    if (account.setup) {
                      openCompaniesModal();
                    } else {
                      setSetupAccountModalOpen(true);
                    }
                  }}
                  accountIsSetup={account.setup}
                />
              ) : isOwnerOrAdmin(account) ? (
                <PersonalProjectsList
                  account={account}
                  refreshProjects={refetch}
                  searchProjectsValue={searchProjectsValue}
                  licensedProjects={data.procoreProjects.filter((p) => p.licensed)}
                  onAllowAccessClick={onAllowAccessClick}
                />
              ) : (
                <CollaboratorProjectList
                  account={account}
                  searchProjectsValue={searchProjectsValue}
                  licensedProjects={data.procoreProjects.filter((p) => p.licensed)}
                />
              )}
            </TabPanel>
            {isOwnerOrAdmin(account) && account.setup && (
              <TabPanel
                value={"users"}
                sx={{
                  padding: '0px 28px',
                  display: state.selectedTab === "users" ? "flex" : "none",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {loadingTeamHome ? (
                  <Loading loadingLabel="Loading projects..." />
                ) : (
                  <AccountUsersDataGrid
                    account={account}
                  />
                )}
              </TabPanel>
            )}
            {isOwnerOrAdmin(account) && account.automaticExportsEnabled && (
              <TabPanel
                value={'automations'}
                sx={{padding: '0px 28px', flex: '1 1 0'}}
              >
                { (loadingExtractAutomations || !extractAutomationsData) && (<CircularProgress/>)}
                { !loadingExtractAutomations && extractAutomationsData && (<AutomationList
                    automations={extractAutomationsData.extractAutomations}
                    account={account}
                    refetchAccountAutomations={() => {
                      refetchAutomations();
                    }}
                    handleConfiguredAutomations={handleConfiguredAutomations}
                />)}
              </TabPanel>
            )}
          </TabContext>
      </BodyCard>
      <FeedbackDialog
        maxWidth={"sm"}
        onClose={() => {
          setIsModalOpen(false);
        }}
        aria-labelledby="help-dialog-title"
        open={isModalOpen}
      >
        <Box marginLeft={"32px"} marginRight={"32px"} marginTop={"18px"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6">{"Select Companies"}</Typography>
            <IconButton
              aria-label="close"
              onClick={() => setIsModalOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="subtitle1">
            {"Select the companies with projects you want to extract from."}
          </Typography>
        </Box>
        <ManageCompaniesDialogContent dividers={false}>
          {loadingAccountCompanies === undefined || loadingAccountCompanies ? (
            <Loading loadingLabel="Loading companies..." />
          ) : (
            <List>
              {accountCompanyData &&
                accountCompanyData.procoreCompanies.map((company) => {
                  const checked =
                    companiesToEnable[company.procoreServerId] || false;
                  return (
                    <ManageCompaniesListItem
                      key={`company-list-item-${company.procoreServerId}`}
                    >
                      <ManageCompaniesListIcon>
                        <ExtractsCheckbox
                          edge="start"
                          checked={checked}
                          onClick={() =>
                            updateCompaniesEnabled(
                              company.procoreServerId,
                              !checked
                            )
                          }
                          disableRipple
                        />
                      </ManageCompaniesListIcon>
                      <ListItemText key={`company-${company.procoreServerId}`}>
                        {company.name}
                      </ListItemText>
                    </ManageCompaniesListItem>
                  );
                })}
              <ListItemText />
            </List>
          )}
        </ManageCompaniesDialogContent>
        <DialogDivider />
        <MyDialogActions>
          <Button
            onClick={() => setIsModalOpen(false)}
            variant="outlined"
            disableElevation={true}
            size="large"
          >
            {"Cancel"}
          </Button>
          <Button
            size="large"
            variant="contained"
            disableElevation={true}
            disabled={!Object.values(companiesToEnable).some((t) => t)}
            onClick={() => {
              setIsModalOpen(false);
              loadProjectsOnCompanies();
            }}
          >
            {"Load Projects"}
          </Button>
        </MyDialogActions>
      </FeedbackDialog>
      <FeedbackDialog
        maxWidth={"sm"}
        onClose={() => {
          setSetupAccountModalOpen(false);
        }}
        aria-labelledby="help-dialog-title"
        open={isSetupAccountModalOpen}
      >
        <Box marginLeft={"32px"} marginRight={"32px"} marginTop={"18px"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6">{"Name your team"}</Typography>
            <IconButton
              aria-label="close"
              onClick={() => setSetupAccountModalOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <ManageCompaniesDialogContent dividers={false}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            padding={"20px 32px"}
            style={{ gap: "12px" }}
          >
            <OutlineWithLabelTextField
              fullWidth={true}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              autoComplete={"off"}
              type={"text"}
              onChange={(evt) => setAccountName(evt.target.value)}
              placeholder="Enter a team name..."
            />
            <Typography
              style={{
                fontSize: "10px",
                color: Colors.darkishGray,
                fontWeight: 400,
              }}
            >
              Note: this name will be visible to anyone invited to the team
            </Typography>
          </Box>
        </ManageCompaniesDialogContent>
        <DialogDivider />
        <MyDialogActions>
          <Button
            onClick={() => setSetupAccountModalOpen(false)}
            variant="outlined"
            disableElevation={true}
            size="large"
          >
            {"Cancel"}
          </Button>
          <Button
            size="large"
            variant="contained"
            disableElevation={true}
            disabled={isEmpty(accountName) || loadingUpdateAccountName}
            onClick={() => {
              updateAccountName();
            }}
          >
            {loadingUpdateAccountName ? (
              <Box
                display={"flex"}
                flexDirection="row"
                alignItems={"center"}
                justifyContent="center"
              >
                <CircularProgress size={12} style={{ margin: "5px" }} />
                <Typography>Next</Typography>
              </Box>
            ) : (
              "Next"
            )}
          </Button>
        </MyDialogActions>
      </FeedbackDialog>
      <FeedbackDialog
        maxWidth={"sm"}
        onClose={() => {
          setIsAppInstallModalOpen(false);
        }}
        aria-labelledby="help-dialog-title"
        open={isAppInstallModalOpen}
      >
        <Box
          marginLeft={"32px"}
          marginRight={"32px"}
          marginTop={"18px"}
          display="flex"
          flexDirection="column"
          style={{ gap: "8px" }}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <IconButton
              aria-label="close"
              onClick={() => setIsAppInstallModalOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <img
            src="/images/app-install-requires-permission.svg"
            width="134px"
            height="92px"
            style={{ margin: "0 auto" }}
          />
          <Typography
            variant="subtitle1"
            style={{
              color: Colors.darkerGray,
              fontWeight: 500,
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            {`${
              (loadedCompanies &&
                loadedCompanies.loadProjectsOnCompanies
                  .failedProcoreCompanyServerIds.length) ||
              0
            } of the selected companies require an admin user to install apps.`}
          </Typography>
        </Box>
        <ManageCompaniesDialogContent dividers={false}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="30px"
            style={{ gap: "12px" }}
          >
            <Box display="flex" flexDirection="row" style={{ gap: "12px" }}>
              <span
                style={{
                  height: "22px",
                  width: "22px",
                  background: "black",
                  textAlign: "center",
                  borderRadius: "36px",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                1
              </span>
              <Typography style={{ flex: "1" }}>
                {
                  "The companies' admins have been emailed about your installation request."
                }
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" style={{ gap: "12px" }}>
              <span
                style={{
                  height: "22px",
                  width: "22px",
                  background: "black",
                  textAlign: "center",
                  borderRadius: "36px",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                2
              </span>
              <Typography style={{ flex: "1" }}>
                {
                  "Once they’ve successfully installed the app, select the companies again and your projects will load."
                }
              </Typography>
            </Box>
          </Box>
        </ManageCompaniesDialogContent>
        <DialogDivider />
        <MyDialogActions>
          <Button
            size="large"
            variant="contained"
            disableElevation={true}
            onClick={() => {
              setIsAppInstallModalOpen(false);
            }}
          >
            {"Okay"}
          </Button>
        </MyDialogActions>
      </FeedbackDialog>
      {
        isAddProjectsModalOpen && (account.licenseType === 'project' ? (
          <AddProjectsProjectLicenseModal
            account={account}
            open={isAddProjectsModalOpen}
            handleOnClose={(refresh) => {
              setIsAddProjectsModalOpen(false);
              if (refresh) {
                refetch();
              }
              // TODO: Determine if permission modal is required
            }} />
        ) : (
          account.customerType === 'prospect' ? (
            <AddProjectFreeTrialLicenseModal
              account={account}
              open={isAddProjectsModalOpen}
              handleOnClose={(refresh) => {
                setIsAddProjectsModalOpen(false);
                if (refresh) {
                  refetch();
                  handleFreeTrialUsed(account.id);
                }
              }} />
          ) : (
            <AddProjectsAnnualLicenseModal
              account={account}
              open={isAddProjectsModalOpen}
              handleOnClose={(refresh, numberOfLicenses, licensedProcoreProjectServerIds) => {
                setIsAddProjectsModalOpen(false);
                if (refresh) {
                  handleLicenseUsed(account.id, numberOfLicenses);
                  if (account.closeoutLogEnabled) {
                    setIsPermissionRequiredModalOpen({
                      open: true,
                      procoreProjectServerIds: licensedProcoreProjectServerIds
                    });
                  } else {
                    refetch();
                  }
                }
              }} />
          )
          )
        )
      }
      {
        isPermissionRequiredModalOpen.open && (
          <ExtractsProIntegrationPermissionModal
            accountId={account.id}
            procoreProjectServerIds={isPermissionRequiredModalOpen.procoreProjectServerIds}
            openModal={isPermissionRequiredModalOpen.open}
            closeModal={() => {
              refetch();
              setIsPermissionRequiredModalOpen({
                open: false,
                procoreProjectServerIds: []
              });
            }} />
        )
      }
      {
        enableDmsaOnProjectsModalState.open && (
          <EnableDmsaOnProjectsModal
            account={account}
            procoreProjects={enableDmsaOnProjectsModalState.procoreProjects}
            open={enableDmsaOnProjectsModalState.open}
            handleOnClose={(refresh) => {
              setEnableDmsaOnProjectsModalState({
                open: false,
                procoreProjects: []
              });
              if (refresh) {
                refetch();
              }
            }} />
        )
      }
    </>
  );
};
export default TeamHome;